import { type PricingCalculatorData, maybeParseNum } from '@lib/calculations';
import type { PricingConfigResponse } from '@lib/responses';
import { PricingStrategyError } from '@lib/validation/calcs/pricing';
import {
  FixedPricingSources,
  type PricingConfig,
  PricingStrategy,
} from '@prisma/client';
import is from '@sindresorhus/is';
import { match } from 'ts-pattern';
import type { UnknownRecord } from 'type-fest';
import type {
  CustomerTiersData,
  PriceParts,
  PricingCalculatorStrategy,
  RecalcLineData,
} from '../../types';
import { AbstractPricingStrategy } from '../abstract';

/** Tier map key for single-tiered pricing */
export const DEFAULT_TIER_CODE = Symbol('DEFAULT_TIER_CODE');

export interface QtyPrice {
  qty: number;
  price: number;
}

export interface QtyPriceMap {
  [DEFAULT_TIER_CODE]?: QtyPrice[];
  [key: string]: QtyPrice[] | undefined;
}
export class FixedPricingStrategy
  extends AbstractPricingStrategy
  implements PricingCalculatorStrategy
{
  public readonly name: PricingStrategy = PricingStrategy.FIXED;
  protected fixedPricingSrc: FixedPricingSources;
  protected discountCode: string | null = null;
  protected marketCode: string | null = null;
  protected customerId: string | null = null;
  /** i.e. market/discount, useful for logging */
  protected resolvedTierCode: string | typeof DEFAULT_TIER_CODE | null = null;
  protected resolvedPricingTiers: QtyPriceMap;
  protected resolvedFixedPricing: QtyPrice[] = [];

  constructor(
    pricingConfig: PricingConfig | PricingConfigResponse,
    data: PricingCalculatorData,
  ) {
    super(pricingConfig, data);
    const { fixedPricingSrc } = this.pricingConfig;
    // TODO: throwing in constructors is probably an anti-pattern; we need to
    //  figure out how to define validation schemas for required fields that
    //  can be used for config form input validation and dynamically in the
    //  calculator factory, ideally in a type-safe manner
    if (!fixedPricingSrc) {
      console.error(
        'FixedPricingStrategy error: Missing fixed pricing source in config',
        {
          pricingConfig,
          data,
        },
      );
      throw new PricingStrategyError(
        'Unable to suggest pricing: missing fixed pricing source in pricing config',
        { strategy: this.name, pricingConfig },
        9000,
      );
    }
    this.fixedPricingSrc = fixedPricingSrc;
    const { discountCode, marketCode } = this.quoteData.customerHeader;
    this.discountCode = discountCode ?? null;
    this.marketCode = marketCode ?? null;
    this.customerId = this.quoteData.customerHeader.customerId ?? null;

    this.resolvedPricingTiers = match(fixedPricingSrc)
      .returnType<QtyPriceMap>()
      .with(FixedPricingSources.DiscountPricing, () => {
        const discountCodeMap = this.partHistoryCalcs?.discountPricing.codeMap;

        if (!discountCodeMap) {
          return this.throwWithContext(
            `Unable to suggest intial prices for part ${this.partId}: missing discount pricing data`,
            {},
            8500,
          );
        }
        return discountCodeMap;
      })
      .with(FixedPricingSources.MarketPricing, () => {
        const marketCodeMap = this.partHistoryCalcs?.marketPricing.codeMap;
        if (!marketCodeMap) {
          return this.throwWithContext(
            `Unable to suggest prices for part ${this.partId}: missing market pricing data`,
            {},
            8500,
          );
        }
        return marketCodeMap;
      })
      .with(FixedPricingSources.NegotiatedPricing, () => {
        const negotiatedPrices =
          this.partHistoryCalcs?.negotiatedPricing.qtyPrices;
        if (!negotiatedPrices) {
          return this.throwWithContext(
            `Unable to suggest prices for part ${this.partId}: missing negotiated pricing data`,
            {},
            8500,
          );
        }
        return { [DEFAULT_TIER_CODE]: negotiatedPrices };
      })
      .with(FixedPricingSources.CustomerPricing, () => {
        const customerPriceMap = this.partHistoryCalcs?.customerPricing.codeMap;

        if (!customerPriceMap) {
          return this.throwWithContext(
            `Unable to suggest intial prices for part ${this.partId}: missing customer pricing data`,
            {},
            8500,
          );
        }
        return customerPriceMap;
      })
      .exhaustive();

    this.setResolvedFixedPricing();
  }
  public calcInitPrice(qty: number): number | null {
    if (!this.resolvedFixedPricing.length) {
      return this.throwWithContext(
        `Unable to suggest price for part ${
          this.partId
        }: no fixed pricing found${
          is.string(this.resolvedTierCode)
            ? ` for tier ${this.resolvedTierCode}`
            : ''
        }`,
        { qty },
      );
    }
    // Iterate in reverse (highest -> lowest qty) and return the
    // first price above the break
    for (let i = this.resolvedFixedPricing.length - 1; i >= 0; i--) {
      const qtyPrice = this.resolvedFixedPricing[i];
      if (qty >= qtyPrice.qty) {
        return qtyPrice.price;
      }
    }
    return this.throwWithContext(
      `Unable to suggest price for part ${this.partId}: no price found for quantity ${qty}`,
      { qtyInput: qty },
    );
  }

  /**
   * Intentionally doesn't alter input calcs/pricing as it is assumed only
   * direct price edits (if any) should be allowed to update the price. Pricing
   * configs using a fixed price strategy should set a different recalc strategy
   * if a different behavior is desired when updating quantity,costs, etc. **/
  public recalcPrice(lineData: RecalcLineData): PriceParts {
    return this.maybeParsePriceParts(lineData);
  }

  /** Override resets the base & final unit price to the initial mapped value by
   * qty before calling the primary recalc method*/
  public recalcPriceOnQtyChange(lineData: RecalcLineData): PriceParts {
    return this.resetAndRecalc(lineData);
  }

  public recalcPriceOnCustomerTierChange(
    lineData: RecalcLineData,
    tiers: CustomerTiersData,
  ): PriceParts {
    this.marketCode = tiers.marketCode ?? null;
    this.discountCode = tiers.discountCode ?? null;
    this.setResolvedFixedPricing();
    return this.resetAndRecalc(lineData);
  }

  protected resetAndRecalc(lineData: RecalcLineData): PriceParts {
    const { quantity } = lineData;
    const newBasePrice = this.calcInitPrice(maybeParseNum(quantity) ?? 0);

    return this.recalcPrice({
      ...lineData,
      baseUnitPrice: newBasePrice,
      unitPrice: newBasePrice,
    });
  }

  /** Resolves and sets tier code & fixed pricing by source */
  private setResolvedFixedPricing() {
    const codeMap: Record<
      FixedPricingSources,
      string | typeof DEFAULT_TIER_CODE | null
    > = {
      [FixedPricingSources.MarketPricing]: this.marketCode, // !TODO: figure out if we need fallback behavior currently (RAF)
      [FixedPricingSources.DiscountPricing]: this.discountCode, // !TODO: figure out if we need fallback behavior currently (ASM)
      [FixedPricingSources.CustomerPricing]: this.customerId, // !TODO: figure out if we need fallback behavior currently
      [FixedPricingSources.NegotiatedPricing]: DEFAULT_TIER_CODE,
    };

    this.resolvedTierCode = codeMap[this.fixedPricingSrc];
    this.resolvedFixedPricing =
      this.resolvedPricingTiers[this.resolvedTierCode ?? DEFAULT_TIER_CODE] ??
      [];
  }

  public throwWithContext(
    message: string,
    addCtx: UnknownRecord = {},
    severityOverride?: number,
  ) {
    return super.throwWithContext(
      message,
      {
        ...addCtx,
        fixedPricingSrc: this.fixedPricingSrc,
        discountCode: this.discountCode,
        marketCode: this.marketCode,
        resolvedTierCode: this.resolvedTierCode,
        resolvedPricingTiers: this.resolvedPricingTiers,
        resolvedFixedPricing: this.resolvedFixedPricing,
      },
      severityOverride,
    );
  }
}
