export const OriginalSource = {
    DWH_STG_VIS: "DWH_STG_VIS",
    DWH_STG_MWIFAS: "DWH_STG_MWIFAS",
    SNAP: "SNAP"
} as const;
export type OriginalSource = (typeof OriginalSource)[keyof typeof OriginalSource];
export const ResourceEnum = {
    QUOTE: "QUOTE",
    USER: "USER",
    SITE: "SITE",
    CUSTOMER: "CUSTOMER",
    METRICS: "METRICS",
    ADMIN: "ADMIN",
    ADMIN_QUOTING: "ADMIN_QUOTING",
    ADMIN_USERS: "ADMIN_USERS",
    ADMIN_SITE: "ADMIN_SITE",
    ADMIN_METRICS: "ADMIN_METRICS",
    ALPHA: "ALPHA",
    BETA: "BETA",
    DEVELOPER: "DEVELOPER",
    SUPERADMIN: "SUPERADMIN"
} as const;
export type ResourceEnum = (typeof ResourceEnum)[keyof typeof ResourceEnum];
export const UserStatus = {
    ACTIVE: "ACTIVE",
    PENDING: "PENDING",
    DISABLED: "DISABLED"
} as const;
export type UserStatus = (typeof UserStatus)[keyof typeof UserStatus];
export const ERPDB = {
    VISUAL: "VISUAL",
    VISUALSPR: "VISUALSPR",
    VISUALFAS: "VISUALFAS",
    EPICOR: "EPICOR"
} as const;
export type ERPDB = (typeof ERPDB)[keyof typeof ERPDB];
export const ConfigStatus = {
    ACTIVE: "A",
    INACTIVE: "I"
} as const;
export type ConfigStatus = (typeof ConfigStatus)[keyof typeof ConfigStatus];
export const ConfigEntities = {
    SITE: "S",
    USER: "U",
    CUSTOMER: "C",
    PART: "P",
    QUOTE: "Q",
    QUOTE_LINE: "QL",
    QTY_BRK: "QLQB",
    REQ_PART: "RP"
} as const;
export type ConfigEntities = (typeof ConfigEntities)[keyof typeof ConfigEntities];
export const QtyBasisSources = {
    LAST_ORDER: "LO",
    HIST_WORK_ORDER_ACT: "HWA",
    HIST_WORK_ORDER_EST: "HWE",
    MASTER_EST: "ME",
    FIXED_PRICING: "FP",
    LAST_QUOTE: "LQ",
    CONFIG_DEFAULT: "DEFAULT"
} as const;
export type QtyBasisSources = (typeof QtyBasisSources)[keyof typeof QtyBasisSources];
export const DisplayMagnitude = {
    ONES: "ONES",
    THOUSANDS: "THOUSANDS"
} as const;
export type DisplayMagnitude = (typeof DisplayMagnitude)[keyof typeof DisplayMagnitude];
export const CostInputSources = {
    HIST_WORK_ORDER_ACT: "HWA",
    HIST_WORK_ORDER_EST: "HWE",
    MASTER_EST: "ME",
    MANUAL_ENTRY: "MANUAL_ENTRY",
    CONFIG_DEFAULT: "CONFIG_DEFAULT"
} as const;
export type CostInputSources = (typeof CostInputSources)[keyof typeof CostInputSources];
export const PricingStrategy = {
    TARGET_CONTR_MARGIN: "TCM",
    TARGET_GROSS_MARGIN: "TGM",
    FIXED: "F",
    RAF_CUSTOM: "RAF"
} as const;
export type PricingStrategy = (typeof PricingStrategy)[keyof typeof PricingStrategy];
export const FixedPricingSources = {
    CustomerPricing: "CUST",
    MarketPricing: "MKT",
    DiscountPricing: "DISC",
    NegotiatedPricing: "NEG"
} as const;
export type FixedPricingSources = (typeof FixedPricingSources)[keyof typeof FixedPricingSources];
export const PartSource = {
    VISUAL: "VISUAL",
    SNAP: "SNAP"
} as const;
export type PartSource = (typeof PartSource)[keyof typeof PartSource];
export const PartStatus = {
    A: "A",
    I: "I",
    O: "O"
} as const;
export type PartStatus = (typeof PartStatus)[keyof typeof PartStatus];
export const QuoteStatus = {
    NEW: "NEW",
    INPROGRESS: "INPROGRESS",
    SENT: "SENT",
    WON: "WON",
    LOSS: "LOSS",
    DELETED: "DELETED",
    CANCELLED: "CANCELLED",
    INHOUSE: "INHOUSE",
    READY: "READY",
    TEST: "TEST"
} as const;
export type QuoteStatus = (typeof QuoteStatus)[keyof typeof QuoteStatus];
export const QuoteLineStatus = {
    ACTIVE: "ACTIVE",
    DELETED: "DELETED"
} as const;
export type QuoteLineStatus = (typeof QuoteLineStatus)[keyof typeof QuoteLineStatus];
export const OrderStatus = {
    C: "C",
    X: "X",
    R: "R",
    H: "H",
    F: "F"
} as const;
export type OrderStatus = (typeof OrderStatus)[keyof typeof OrderStatus];
export const OrderLineStatus = {
    A: "A",
    C: "C"
} as const;
export type OrderLineStatus = (typeof OrderLineStatus)[keyof typeof OrderLineStatus];
export const DocumentType = {
    MASTER_QUOTE: "MASTER_QUOTE",
    QUOTE_DOCUMENT: "QUOTE_DOCUMENT"
} as const;
export type DocumentType = (typeof DocumentType)[keyof typeof DocumentType];
export const FeedbackType = {
    RECOMMENDED_PRICE_CHANGE: "RECOMMENDED_PRICE_CHANGE",
    RECOMMENDED_QUANTITY_CHANGE: "RECOMMENDED_QUANTITY_CHANGE",
    RECOMMENDED_LEAD_TIME_CHANGE: "RECOMMENDED_LEAD_TIME_CHANGE"
} as const;
export type FeedbackType = (typeof FeedbackType)[keyof typeof FeedbackType];
export const QuoteFeedbackReason = {
    COST_VARIANCES: "COST_VARIANCES",
    AGGRESSIVE_PRICING: "AGGRESSIVE_PRICING",
    INDUSTRY_MARKET_SEGMENTATION: "INDUSTRY_MARKET_SEGMENTATION",
    OTHER: "OTHER"
} as const;
export type QuoteFeedbackReason = (typeof QuoteFeedbackReason)[keyof typeof QuoteFeedbackReason];
export const PartEngineeringType = {
    QUOTE: "QUOTE",
    ENGINEERING_MASTER: "ENGINEERING_MASTER",
    WORK_ORDER: "WORK_ORDER"
} as const;
export type PartEngineeringType = (typeof PartEngineeringType)[keyof typeof PartEngineeringType];
export const PartEngineeringStatus = {
    CLOSED: "CLOSED",
    FIRMED: "FIRMED",
    RELEASED: "RELEASED",
    UNRELEASED: "UNRELEASED",
    CANCELLED: "CANCELLED"
} as const;
export type PartEngineeringStatus = (typeof PartEngineeringStatus)[keyof typeof PartEngineeringStatus];
export const RunRateType = {
    PCS_PER_HR: "PPH",
    HRS_PER_PC: "HPP",
    MIN_PER_PC: "MPP",
    DAYS_PER_PC: "DPP",
    HRS_PER_LOAD: "HPL",
    DAYS_PER_LOAD: "DPL",
    PCS_PER_MIN: "PPM",
    PCS_PER_DAY: "PPD",
    LOADS_PER_HR: "LPH",
    LOADS_PER_DAY: "LPD"
} as const;
export type RunRateType = (typeof RunRateType)[keyof typeof RunRateType];
export const ScrapYieldType = {
    SCRAP: "SCRAP",
    YIELD: "YIELD"
} as const;
export type ScrapYieldType = (typeof ScrapYieldType)[keyof typeof ScrapYieldType];
export const UnitsPerPieceType = {
    START: "START",
    END: "END"
} as const;
export type UnitsPerPieceType = (typeof UnitsPerPieceType)[keyof typeof UnitsPerPieceType];
